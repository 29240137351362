const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'basis_loader', {
	schema: {
		active: { type: 'boolean', default: true },
	},

	init() {

		if ( ! this.data.active ) return;

		this.basisLoader = this.el.sceneEl.systems[ 'asset_helper' ].basisLoader;
		this.textureLoader = this.el.sceneEl.systems[ 'asset_helper' ].textureLoader;

		if ( this.el.getObject3D( 'mesh' ) ) {

			this.applyTextures();

		} else {

			this.el.addEventListener( 'object3dset', () => {

				this.applyTextures();

			} );

		}



	},

	applyTextures: async function () {

		let meshGroup = this.el.getObject3D( 'mesh' );

		const promises = [];

		meshGroup.traverse( ( node ) => {

			if ( ! node.isMesh ) return;

			if ( node.material.userData.basisSrc ) {

				promises.push(

					new Promise( ( resolve ) => {

						this.basisLoader.load( `/textures/${node.material.userData.basisSrc}.basis`,
							( texture ) => {

								if ( node.material.userData.basisSrc.includes( 'alpha' ) ) {

									// handling issue on iPads and some phones where basis textures with alpha show up black and white
									if ( texture.format === THREE.RGB_PVRTC_4BPPV1_Format ) {

										this.loadPNGInstead( node.material, resolve );
										texture.dispose();
										return;

									}

									node.material.transparent = true;

								}

								texture.encoding = THREE.sRGBEncoding;

								node.material.map = texture;

								node.material.needsUpdate = true;

								resolve();

							}
						);

					} )
				);

			}

		} );

		await Promise.all( promises );

		this.el.emit( 'textures-loaded' ); // listened to by overview_mode_controller


	},


	loadPNGInstead: async function ( material, resolve ) {

		this.textureLoader.load( `/textures/scene/${material.userData.basisSrc}.png`,
			( texture ) => {

				texture.encoding = THREE.sRGBEncoding;
				texture.flipY = false;

				material.transparent = true;

				material.map = texture;

				material.needsUpdate = true;

				resolve();

			}
		);

	}

} );
