// packages
import 'aframe-orbit-controls';

// controllers
import "./components/camera/touch-look-controls";
import "./components/camera/zoom_controls";
import "./components/camera/mobile_fov";

// physics

// assets in the scene
import "./components/environment_lighting";
import "./components/gltf_material";
import "./components/basis_loader";
import "./components/shadow_plane";

// functions / helpers
import "./systems/asset_helper";
import "./systems/smart_stats";
import "./systems/gui_debugger";
import "./components/log_gltf";
import './polyfills';
import aframeFromJson from './modules/aframe-from-json';



const AFRAME = window.AFRAME;


//____ init aframe scene ____
async function aframeInit() {


	// convert scene's json to html and inject into DOM
	var sceneJson = require( './json/a-scene.json' );
	var sceneHtml = aframeFromJson( sceneJson );
	document.body.appendChild( sceneHtml );

	// ____________________________
	// __ loading sequence below __
	// ____________________________

	// setup promise for scene to load
	let sceneLoaded = new Promise( ( resolve ) => {

		document.getElementById( 'scene' ).addEventListener( 'loaded', resolve );

	} );

	// setup promise for motorcycle models to load
	let motorcycleNormalsLoaded = new Promise( ( resolve ) => {

		document.getElementById( 'guitar-normals' ).addEventListener( 'model-loaded', resolve );

	} );

	// setup promise for all other assets to load
	let assetsLoaded = new Promise( ( resolve ) => {

		document.addEventListener( 'AllAssetsAreLoaded', resolve );

	} );

	// setup promise for envMap to be applied
	let envMapApplied = new Promise( ( resolve ) => {

		document.addEventListener( 'EnvMapApplied', resolve );

	} );


	// NOTE: can add other event listeners here for more custom loading sequence
	await Promise.all( [ sceneLoaded, motorcycleNormalsLoaded, assetsLoaded, envMapApplied ] );

	// slight buffer to let everything load into GPU and render first time
	setTimeout( () => {

		// signal to react UI that scene is ready to go
		document.dispatchEvent( new CustomEvent( 'RevealScene' ) );

	}, 1800 );

	// start auto-rotate after the scene has been revealed
	setTimeout( () => {

		let guitar = document.getElementById( 'guitar-normals' );
		AFRAME.utils.entity.setComponentProperty( guitar, 'animation.enabled', true );

	}, 3000 );

}

aframeInit();

