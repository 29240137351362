/**Safari doesn't show shadow when implemented through AFrame properties.
 * Have to inject it like this
 */

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent('shadow_plane', {

  init() {
    const planeGeometry = new THREE.PlaneBufferGeometry( 7.5, 7.5 );
		planeGeometry.rotateX( - Math.PI / 2 );
		const planeMat = new THREE.ShadowMaterial( { opacity: 0.420 } );
		const plane = new THREE.Mesh( planeGeometry, planeMat );
		plane.position.set( 0, .05, 0 );
    plane.name = 'three-shadow-plane';
		plane.receiveShadow = true;
		this.el.sceneEl.object3D.add( plane );
  }
})